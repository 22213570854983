.TerraformModal{
  min-width: 250px;
  // min-height: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  outline: none;
  padding: 20px;
  background-color: #000;
  > div {
    border: solid 1px #fff;
    overflow: hidden;
  }
}


.RequestingTerraform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 0;
  > div {
    margin-top: 30px;
    margin-bottom: -3px;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    svg {
      width: 250px;
      height: auto;
      fill: #fff;
      &:first-child {
        width: 70px;
        > g {
          > path {
            &:nth-child(1) {
              animation: signalFlicker 2s forwards linear infinite;
            }
            &:nth-child(2) {
              opacity: 0;
              animation: signalFlicker 2s 1s forwards linear infinite;
            }
          }
        }
      }
      &:nth-child(2) {
        margin-bottom: -10px;
        margin-left: 100px;
        position: relative;
        left: 10px;
        transform: translate(0,150%) scale(2) rotate(20deg);
        opacity: 0.4;
        animation: satelliteRise 5s 1s forwards ease-in-out;
      }
    }
  }
}

.TerraformInProgress{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 0;
}

.TerraformCountdownRow{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TerraformScanReports {
  width: 360px;
  margin: 10px auto;
}

@keyframes satelliteRise {
  0% {
    transform: translate(0,150%) scale(2) rotate(20deg);
    opacity: 0.4;
  }
  100%{
    transform: translate(0,0) scale(1) rotate(0deg);
    opacity: 1
  }
}

@keyframes signalFlicker {
  0%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}



