* {
  z-index: 2;
}


html, body, #root {
  height: 100lvh;
  background-color: #000;
}

canvas {
  background-color: #000;
}

.App {
  text-align: center;
}

.standard-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background: rgba(0,0,0,0.88);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 10px 20px 25px 0px rgba(0,0,0,0.5);
  z-index: 2;
  outline: 0;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.standard-modal .inner {
  border: solid 1px rgba(255,255,255,0.4);
  border-radius: 10px;
  padding: 25px;
  max-width: 100%;
  overflow-y: auto;
  /* max-width: 400px; */
}