@import '../../mixins.scss';

.HomePage{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonGang {
  display: flex;
  flex-direction: column;
  @include for-breakpoint("sm") {
    width: 90%;
  }
}

.shinyButton {
  position: relative;
  border: 0;
  outline: 0;
  padding: 20px 95px;
  margin: 10px 0 20px 0;
  cursor: pointer;
  box-shadow: inset 0px 0px 15px 0px rgba(0,174,239,0.3);
  background: linear-gradient(180deg, rgba(0,0,0,0.9) 0%, rgba(37,37,37,0.85) 100%);
  transition: box-shadow 800ms;
  border-radius: 20px;
  font-family: "ofelia-display", sans-serif;
  font-weight: 400;
  font-size: 33px;
  color: #fff;
  letter-spacing: 1px;
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 20px;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: 50%;
    background: linear-gradient(rgba(0,174,239,0.5), rgba(0,174,239,0.08));
    opacity: 0.5;
    transition: opacity 800ms;
  }
  &:hover {
    box-shadow: inset 0px 0px 20px 2px rgba(0,174,239,0.83), 0px 0px 15px 0px rgba(0,174,239,0.6);
    &:after {
      opacity: 1;
    }
  }
  @include for-breakpoint("md") {
    padding: 15px 20px;
    
  }
}