.ResetButton {
  border: 0;
  padding: 20px;
  margin: 20px auto;
  width: 100%;
  // background: rgba(0,0,0,1);
  background: var(--mui-palette-UltraPlush-cyan);
  transition: background-color 500ms, border-color 500ms, border-width 500ms, box-shadow 500ms;
  cursor: pointer;
  outline: 0;
  // border-style: solid;
  // border-width: 1px;
  // border-color: rgba(255,255,255,0.2);
  // border-color: var(--mui-palette-UltraPlush-cyan);
  box-shadow:0px 0px 0px 2px rgba(109,206,242,1) inset, -10px 10px 20px 0px rgba(0,0,0,0.6);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 1.3em;
    font-weight: 600;
    letter-spacing: 0.05em;
    color: #fff;
    transform: scale(1);
    transition: font-size 500ms, transform 500ms;
  }
  &:hover{
    background-color: var(--mui-palette-UltraPlush-cyan);
    // border-color: rgba(255,255,255,1);
    // border-width: 5px;
    box-shadow:0px 0px 0px 5px rgba(109,206,242,1) inset, -15px 15px 30px 0px rgba(0,0,0,0.8);;
    span {
      transform: scale(1.1);
    }
  }
}