.CameraOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 22;
}

.CameraOverlayStatic {
  composes: CameraOverlay;
}

.reticle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // pointer-events: none;
}

.reticleElement {
  position: absolute;
  height: 20lvh;
  width: 15lvw;
  border-width: 0.6lvw;
  border-color: #fff;
  border-radius: 10px;
  border-style: none;
}

.topLeft {
  composes: reticleElement;
  top: 15px;
  left: 15px;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-left-style: solid;
  border-top-style: solid;
}

.topRight {
  composes: reticleElement;
  position:absolute;
  top: 15px;
  right: 15px;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-right-style: solid;
  border-top-style: solid;
}

.bottomLeft {
  composes: reticleElement;
  bottom: 15px;
  left: 15px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-left-style: solid;
  border-bottom-style: solid;
}

.bottomRight {
  composes: reticleElement;
  position:absolute;
  bottom: 15px;
  right: 15px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-right-style: solid;
  border-bottom-style: solid;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
  width: 10lvh;
  height: 10lvh;
  border: solid 0.3lvh #fff;
  border-radius: 999px;
  backdrop-filter: blur(0.8lvh);
  z-index: 2;
}

.centerCross {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
  z-index: 3;
  width: 10lvh;
  height: 10lvh;
  &::before{
    content:'';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    border-bottom: solid 1px #fff;
  }
}

.shutter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  opacity: 0;
}

.shutterButton {
  position: absolute;
  bottom: 4lvh;
  left: 50%;
  transform: translate3d(-50%,0,0);
  z-index: 3;
  width: 10lvh;
  height: 10lvh;
  background: #fff;
  border-radius: 999px;
  box-shadow: -5px 5px 10px 0 rgba(0,0,0,0.2);
  cursor: pointer;
  border: solid 1px rgba(0,0,0,0.5);
}

.shutterButtonStatic {
  composes: shutterButton;
  bottom: 10vh;
}