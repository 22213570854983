.MenuButton {
  width: 35px;
  height: 35px;
  border: solid 1px rgba(255,255,255,0.1);
  border-right: 0;
  border-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  svg {
    width: 40px;
    height: 40px;
    opacity: 0.7;
  }
}

.MenuButtonOpen {
  composes: MenuButton;
  background: rgba(255,255,255,0.1);
}