.MainMenu {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate3d(0,105%,0);
  z-index: 999999999;
  min-width: 50%;
}

.MenuItem {
  background: #000;
  cursor: pointer;
  padding: 10px 10px 10px 5px;
  margin-bottom: 5px;
  text-align: right;
  border: solid 1px transparent;
  transition: letter-spacing 500ms, padding 500ms;
  &:hover {
    background: rgba(255,255,255,1);
    border: solid 1px #000;
    letter-spacing: 1px;
    padding-right: 15px;
    span {
      color: #000;
      // color: var(--mui-palette-UltraPlush-cyan)
    }
  }
}
.welcomeBack {
  background: #000;
  margin-bottom: 10px;
  text-align: center;
  padding: 10px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
    span {
      position: relative;
      top: 3px;
      opacity: 0.8;
    }
    img {
      height: 2.2em;
      margin-left: 10px;
      // position: relative;
      // top: 4px;
    }
  }
  
}