@import '../../mixins.scss';

.FloatingHeader {
  height: 130px;
  margin-bottom: 20px;
  z-index: 999999;
  @include for-breakpoint('sm') {
    height: 100px;
  }
} 

.inner {
  position: relative;
  background: rgba(10,10,10,0.85);
  // margin-bottom: 20px;
  padding: 20px;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.5);
  margin-top: 10px;
  padding: 20px 80px;
  display: inline-block;
  position: relative;
  border-radius: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transition: height 1000ms, top 1000ms;
  @include for-breakpoint("sm") {
    width: 100%;
    padding: 15px 20px;
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 0px;
    // overflow: hidden;
    h1 {
      position: relative;
      top: -0.3em;
    }
  }
  h1 {
    transition: letter-spacing 1500ms, font-size 1000ms !important;
  }
}

.innerSticky {
  composes: inner;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0;
  z-index: 9999;
  padding: 0;
  min-width: 400px;
  padding-bottom: 45px;
  h1 {
    font-size: 1.7em !important;
    letter-spacing: 2.6px;
  }
  @include for-breakpoint("sm") {
    min-width: unset;
    padding-bottom: 30px;
    h1 {
      top: 2px;
    }
  }
  // @include for-breakpoint("sm") {
  //   width: 100%;
  //   padding: 15px 20px;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   // overflow: hidden;
  // }
  
}