.InsufficientStardust{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  position: relative;
  img {
    width: 100%;
    height: auto;
    opacity: 0.5;
    z-index: 0;
  }
}

.inner {
  position: absolute;
  padding: 25px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  button {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    background: var(--mui-palette-Outworlds-loginBlue) !important;
    color: #fff;
  }
}