.ConfirmTerraform{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  position: relative;
  img {
    width: 100%;
    height: auto;
    opacity: 0.5;
    z-index: 0;
  }
}

.inner {
  position: absolute;
  padding: 25px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  button {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    background: var(--mui-palette-Outworlds-loginBlue) !important;
    color: #fff;
  }
}


.buttonGang{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-template-rows: 100%;
  button {
    &:nth-child(1) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      background: #555 !important;
      color: rgba(0,0,0,0.65)
    }
    &:nth-child(2) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      background: var(--mui-palette-Outworlds-loginBlue) !important;
    }
  }
}