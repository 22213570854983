@import '../../../../mixins.scss';
.SelectStardustModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 550px;
  position: relative;
  background-color: #000;
  @include breakpoint-down("sm") {
    width: 90lvw;
  }
  > div {
    width: 100%;
  }
  img {
    width: 100%;
  }
  button {
    width: 93%;
    padding: 10px;
    margin: 20px auto 10px;
    display: block;
    background: var(--mui-palette-Outworlds-loginBlue);
    color: #fff !important;
  }
}

.SelectionOptions {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-template-rows: 100%;
  column-gap: 15px;
  padding: 10px 30px 10px 30px;
  @include breakpoint-down("sm") {
    padding: 10px 10px;
  }
}

.SelectionOption {
  display: block;
  border: solid 1px rgba(255,255,255,0.25);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
  padding: 20px;
  cursor: pointer;
  &:hover {
    background: rgba(255,255,255,0.1);
  }
  @include breakpoint-down("sm") {
    width: 100%;
    padding: 10px 0;
  }
}

.SelectionOptionSelected {
  composes: SelectionOption;
  background: rgba(255,255,255,0.05);
  border: solid 1px var(--mui-palette-UltraPlush-cyan);
}