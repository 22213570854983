@import '../../mixins.scss';

.ExplorePage{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.body {
  background: rgba(10,10,10,0.85);
  margin-bottom: 10px;
  // padding: 10px;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.5);
  margin-top: 10px;
  padding: 30px;
  display: grid;
  grid-template-columns:repeat(5,1fr);
  grid-template-rows:repeat(3,1fr);
  grid-column-gap: 30px;
  width: 95%; 
  // animation: expand-in 1s 200ms forwards;
  // overflow: hidden;
  position: relative;
  grid-auto-flow: row;
  @include for-breakpoint("lg") {
    grid-template-columns:repeat(3,1fr);
    grid-template-rows:repeat(4,1fr);
    grid-column-gap: 15px;
    padding: 15px;
  }
  // @include for-breakpoint("md") {
  //   grid-template-columns:repeat(3,1fr);
  //   grid-template-rows:repeat(4,1fr);
  //   grid-column-gap: 15px;
  //   padding: 15px;
  // }
  @include for-breakpoint("sm") {
    grid-template-columns:repeat(2,1fr);
    grid-template-rows:repeat(6,1fr);
    grid-column-gap: 10px;
    padding: 10px;
    margin-top: 55px;
  }
  @include breakpoint-down("sm") {
    grid-template-columns:repeat(1,100%);
    grid-template-rows: auto;
    grid-column-gap: 0;
    padding: 10px;
    margin-top: 55px;
  }
}

@keyframes expand-in {
  0% {
    width: 0;
    padding: 0;
    overflow: hidden;
  }
  100% {
    width: 95%;
    padding: 30px 40px;
    overflow: visible;
  }
}