.FloatingBackButton {
  position: absolute;
  top: -50px;
  left: 0px;
  background: #000;
  border-radius: 10px;
  padding: 10px 20px;
  height: 20px;
  outline: 0;
  border: 0;
  box-sizing: content-box;
  color: #fff;
  font-size: 15px;
  font-family: 'ofelia-display', sans-serif;
  cursor: pointer;
  box-shadow: inset 0px 0px 8px 0px rgba(236,0,140,0.2);
  background: linear-gradient(180deg, rgba(0,0,0,0.9) 0%, rgba(37,37,37,0.85) 100%);
  transition: box-shadow 800ms;
  letter-spacing: 1px;
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 20px;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: 50%;
    background: linear-gradient(rgba(236,0,140,0.3), rgba(236,0,140,0.08));
    opacity: 0.5;
    transition: opacity 800ms;
  }
  &:hover {
    box-shadow: inset 0px 0px 15px 2px rgba(236,0,140,0.53), 0px 0px 10px 0px rgba(236,0,140,0.5);
    &:after {
      opacity: 1;
    }
  }
}