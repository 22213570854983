// .WorldMenu {
//   position: fixed;
//   top: 0;
//   right: 0;
//   height: 100lvh;
//   width: auto;
//   transition: width 500ms;
//   overflow-x: visible;
//   overflow-y: hidden;
// }
.WorldMenuButton {
  position: fixed;
  top: 20px;
  right: 30px;
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--mui-zIndex-snackbar);
  cursor: pointer;
  box-shadow: -5px 5px 5px 0px rgba(0,0,0,0.3);
  z-index: 9999;
  svg {
    width: 80%;
    height: 80%;
    fill: #000;
  }
}

.worldMenuDrawerInner {
  width: 350px;
  padding: 20px;
  height: 100lvh;
  overflow-y: auto;
  overflow-x: hide;
   // scrollbars
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(0,0,0,0.9);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    // background: rgba(255,255,255,0.15);
    // background: var(--mui-palette-UltraPlush-magenta);
    background: rgba(255,255,255,0.3);
    border-radius: 20%;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.viewButton {
  background-color: rgba(255,255,255,0.75) !important;
  border: solid 1px rgba(255,255,255,0.8) !important;
  &:hover {
    background-color: rgba(255,255,255,1) !important;
    border: solid 1px rgba(0,255,255,0.9) !important;
  }
}

.viewLockedButton {
  background-color: rgba(255,255,255,0.4) !important;
  border: solid 1px rgba(0,0,0,0.5) !important;
  svg {
    height: 2.7em;
    width: auto;
    margin-right: 0.7em;
  }
}