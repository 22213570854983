@mixin for-breakpoint($breakpoint) {
  @if $breakpoint == ultrawide {
    @media (max-width:3440px) {@content;}
  }
  @else if $breakpoint == hd {
    @media (max-width:1920px) {@content;}
  }
  @else if $breakpoint == xl {
    @media (max-width:1536px) {@content;}
  }
  @else if $breakpoint == lg {
    @media (max-width:1250px) {@content;}
  }
  @else if $breakpoint == md {
    @media (max-width:900px) {@content;}
  }
  @else if $breakpoint == sm {
    @media (max-width:600px) {@content;}
  }
  @else if $breakpoint == xs {
    @media (max-width:480px) {@content;}
  }
}

@mixin breakpoint-up($breakpoint) {
  @if $breakpoint == ultrawide {
    @media (min-width:3440px) {@content;}
  }
  @else if $breakpoint == hd {
    @media (min-width:1920px) {@content;}
  }
  @else if $breakpoint == xl {
    @media (min-width:1536px) {@content;}
  }
  @else if $breakpoint == lg {
    @media (min-width:1250px) {@content;}
  }
  @else if $breakpoint == md {
    @media (min-width:900px) {@content;}
  }
  @else if $breakpoint == sm {
    @media (min-width:600px) {@content;}
  }
  @else if $breakpoint == xs {
    @media (min-width:480px) {@content;}
  }
}

@mixin breakpoint-down($breakpoint) {
  @if $breakpoint == ultrawide {
    @media (max-width:3440px) {@content;}
  }
  @else if $breakpoint == hd {
    @media (max-width:1920px) {@content;}
  }
  @else if $breakpoint == xl {
    @media (max-width:1536px) {@content;}
  }
  @else if $breakpoint == lg {
    @media (max-width:1250px) {@content;}
  }
  @else if $breakpoint == md {
    @media (max-width:900px) {@content;}
  }
  @else if $breakpoint == sm {
    @media (max-width:600px) {@content;}
  }
  @else if $breakpoint == xs {
    @media (max-width:480px) {@content;}
  }
}