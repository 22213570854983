@import '../../mixins.scss';

.MusicPlayer {
  max-width: 290px;
  width: 60%;
  position: absolute;
  z-index: 999;
  cursor: pointer;
  border: solid 1px rgba(255,255,255,0.1);
  border-bottom-color: rgba(255,255,255,0);
  padding: 5px 10px 5px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  transition: border 1500ms, background-color 1500ms;
}

.MusicPlayerMenuView {
  composes: MusicPlayer;
  top: 103px;
  left: 50%;
  transform: translate(-50%,0);
  
  &:hover {
    border: solid 1px rgba(255,255,255,0.2);
    border-bottom-color: rgba(255,255,255,0);
    span {
      opacity: 1 !important;
    }
    button {
      border: solid 1px rgba(255,255,255,0.3) !important;
    }
    svg {
      opacity: 0.4 !important;
    }
  }
  @include for-breakpoint('sm') {
    top: 60px;
    width: 300px;
    padding: 1px 10px;
  }
}

.MusicPlayerMenuViewSticky {
  composes: MusicPlayerMenuView;
  position: fixed;
  top: 40px;
  border: solid 1px rgba(255,255,255,0.2);
  background-color: rgba(0,0,0,0.7);
  &:hover {
    border-bottom: solid 1px rgba(255,255,255,0.2);
    background-color: rgba(0,0,0,0.8);
  }
  span {
    opacity: 0.3 !important;
    font-size: 10px !important;
  }
  button {
    border: solid 1px rgba(255,255,255,0.2) !important;
  }
  svg {
    opacity: 0.2 !important;
  }
  @include for-breakpoint('sm') {
    top: 41px;
    background: rgba(0,0,0,0.2);
    border-bottom-color: rgba(255,255,255,0.1);
    border-top-color: rgba(255,255,255,0.1);
  }
}

.MusicPlayerWorldView {
  composes: MusicPlayer;
  bottom: 24px;
  right: 24px;
  background: rgba(0,0,0,0.2);

}

.MusicPlayerWorldViewSticky {
  composes: MusicPlayerWorldView;
}

.trackNameBox {
  margin-right: 1.5em;
  overflow: hidden;
}

.trackNameBoxSticky {
  composes: trackNameBox;
  margin-right: 0.8em;
}

.buttonGang {
  display: flex;
  flex-direction: row;
}

.ButtonBase {
  border: solid 1px rgba(255,255,255,0.1);
  aspect-ratio: 1;
  width: 25px;
  height: 25px;
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  transition: border 1500ms;
  svg {
    width: 21px;
    height: 21px;
    fill: #fff;
    opacity: 0.3;
    transition: opacity 1500ms;
  }
}

.playBtn {
  composes: ButtonBase;
}
.pauseBtn {
  composes: ButtonBase;
  padding: 0;
  svg {
    width: 25px;
    height: 25px;
  }
}
.prevBtn {
  composes: ButtonBase;
  margin-right: 4px;
  svg {
    transform: scaleX(-1);
  }
}
.nextBtn {
  composes: ButtonBase;
  margin-left: 4px;
}