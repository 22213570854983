.ControlTypePicker {
  position: fixed;
  top: 87px;
  right: 30px;
  width: 45px;
  height: 45px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
  padding: 5px;
  opacity: 0.6;
  overflow: visible;
  transition: height 200ms;
  cursor: pointer;
  z-index: 99;
  svg {
    width: 100%;
    height: auto;
    fill: #fff;
    position: relative;
    cursor: pointer;
    margin-bottom: 5px;
  }
}

.ControlTypePickerExpanded{
  composes: ControlTypePicker;
  height: 135px;
}