@import '../../mixins.scss';

.WorldPrompt {
  background: rgba(10,10,10,0.85);
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.5);
  width: 95%;
  display: flex;
  padding: 30px 40px;
  position: relative;
  @include breakpoint-down("sm") {
    padding: 20px;
    margin-top: 40px;
  }
}


.WorldPromptInput {
  width: 100%;
  > textarea {
    width: 100%;
    padding: 20px;
    font-size: 24px;
    font-family: "ofelia-display", sans-serif;
    resize:none;
    min-height: 250px;
  }
}

.lowerRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  textarea {
    box-sizing: content-box;
    width: 100%;
    padding: 10px;
    font-size: 24px;
    height: 1em;
    font-family: "ofelia-display", sans-serif;
    resize:none;
  }
  button {
    background-color: rgba(255,255,255,0.8);
    font-size: 22px;
    &:hover {
      background-color: rgba(255,255,255,1);
    }
  }
}

.secondRow {
  composes: lowerRow;
}

.thirdRow {
  composes: lowerRow;
  margin-top: 25px;
  @include breakpoint-down("sm") {
    flex-direction: column;
  }
}

.nameRow {
  flex-grow: 1;
  max-width: 50%;
  div {
    display: flex;
    flex-direction: column;
  }
  textarea {
    width: 250px;
    margin-bottom: 10px;
    @include breakpoint-down("sm") {
      width: 95%
    }
  }
  @include breakpoint-down("sm") {
    max-width: unset;
    margin-bottom: 30px;
  }
}

.TerraformButton {
  background-color: transparent !important;
  background: linear-gradient(0deg, rgba(11,47,9,1) 0%, rgba(0,0,0,0) 50%, rgba(11,47,9,0.1) 100%);
  box-shadow: inset 0px 0px 10px 6px rgba(27,39,24,1), 0px 0px 10px 3px rgba(75,248,65,0.3) !important;
  color: #589625 !important;
  font-weight: 400 !important;
  position: relative;
  text-shadow: 0px 9px 1px rgba(0,0,0,0.61);
  transition: text-shadow 250ms ease, box-shadow 250ms ease !important;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: #d4f9d7;
    opacity: 0.1;
  }
  &:hover {
    text-shadow: 0px 15px 1px rgba(0,0,0,0.61);
    box-shadow: inset 0px 0px 18px 6px rgb(29, 47, 25), 0px 0px 10px 3px rgba(75,248,65,0.5) !important;
    background: linear-gradient(0deg, rgba(11,47,9,1) 0%, rgba(0,0,0,0) 50%, rgba(11,47,9,0.1) 100%);
    color: #67b02c !important;
    &:after {
      content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: #d4f9d7;
    opacity: 0.11;
    }
  }
  svg {
    height: 2.5em;
    width: auto;
    margin-right: 1em;
    fill: rgba(236,0,140,0.6);
  }
  span {
    display: block;
    font-size: 0.8em;
    margin-left: 0.7em;
    img {
      height: 1.2em;
      margin-left: 0.2em;
      // margin-right: 0.1em;
      position: relative;
      top: 0.2em;
    }
  }
  
}

.TerraformButtonLocked {
  composes: TerraformButton;
  
}