@import '../../mixins.scss';

.BaseWorldThumbnail{
  cursor: pointer;
  transition: transform 800ms;
  width: 100%;
  position: relative;
  img {
    border: solid 1px rgba(255,255,255,0.4);
    width: 100%;
    height: auto;
  }
  @include breakpoint-up("lg"){
    &:hover {
      transform: scale(1.2);
    }
  }
  
}

.inner {
  position: relative;
}

.lockOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 1;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    aspect-ratio: 1;
    width: 50%;
    height: auto;
    fill: rgba(255,255,255,0.4);
  }
}